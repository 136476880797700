import ContactForm from "#components/contact/contact-form"
import ContactInfo from "#components/contact/contact-info"
import Container from "#components/global/container"
import Layout from "#components/global/layout"
import Seo from "#components/global/seo"
import { incrementCounter } from "#utils/firebase"
import { useLocation } from "@gatsbyjs/reach-router"
import { graphql } from "gatsby"
import * as React from "react"
import { useEffect } from "react"

const BlogIndex = ({ data }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const socials = data.site.siteMetadata.social

  const location = useLocation()

  useEffect(() => {
    incrementCounter(location, `pages/contact/views`)
  }, [])

  return (
    <Layout siteTitle={{ siteTitle }}>
      <Seo
        title="Contact"
        description="The contacts of a tinker"
        tags="contact"
      />
      <Container className="!pt-0 !md:pt-24">
        <div className="grid md:my-12 md:grid-cols-2">
          <ContactInfo socials={socials} />
          <ContactForm />
        </div>
      </Container>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        social {
          twitter
          facebook
          github
          linkedin
          youtube
        }
      }
    }
  }
`
