import { EnvelopeIcon, MapPinIcon } from '@heroicons/react/24/outline';
import * as React from 'react';

const ContactInfo = ({ socials }) => {
    return (
        <section className="my-10 text-sm">
            <h2 className="text-2xl font-semibold dark:text-white">
                How to reach me?
            </h2>
            <p className="max-w-sm mt-5">
                Want to ask me something? Criticize me? Join me on my adventures?
            </p>

            <p className="max-w-sm mt-5">
                You are just one form-submission away from it.
            </p>

            <div className="mt-5">
                <div className="flex group w-max items-center mt-2 space-x-2 text-dark-600 dark:text-gray-400">
                    <a href={`https://www.google.com/maps/place/Zagreb/`} target="_blank" rel="noopener noreferrer" className='group-hover:text-white transition-al'>
                        <MapPinIcon className="w-6 h-4 inline-block" />
                    </a>
                    <a href={`https://www.google.com/maps/place/Zagreb/`} target="_blank" rel="noopener noreferrer">
                        <span className={`bg-gradient-to-r from-stone-600 bg-[length:0px_5px] bg-left-bottom bg-no-repeat transition-[background-size] duration-500 hover:bg-[length:100%_3px] group-hover:bg-[length:100%_5px]`}>
                            Zagreb, Croatia
                        </span>
                    </a>
                </div>
                <div className="flex group w-max items-center mt-2 space-x-2 text-dark-600 dark:text-gray-400">
                    <a href={`mailto:ivan.lesar.pmf@gmail.com`} target="_blank" rel="noopener noreferrer" className="group-hover:text-white transition-al">
                        <EnvelopeIcon className="w-6 h-4 inline-block" />
                    </a>
                    <a href={`mailto:ivan.lesar.pmf@gmail.com`}>
                        <span className={`bg-gradient-to-r from-stone-600 bg-[length:0px_5px] bg-left-bottom bg-no-repeat transition-[background-size] duration-500 hover:bg-[length:100%_3px] group-hover:bg-[length:100%_5px]`}>
                            ivan.lesar.pmf@gmail.com
                        </span>
                    </a>
                </div>
                <div className="flex group w-max items-center mt-8 space-x-2 text-dark-600 dark:text-gray-400">
                    <a href={`https://fb.me/${socials.facebook}`} target="_blank" rel="noopener noreferrer" className="justify-left items-start group-hover:text-[#3b5998] transition-all">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="w-6 h-4 pl-[7px]" preserveAspectRatio="xMinYMin meet">
                            <path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                        </svg>
                    </a>
                    <a href={`https://fb.me/${socials.facebook}`} target="_blank" rel="noopener noreferrer">
                        <span className={`bg-gradient-to-r from-[#3b5998] bg-[length:0px_5px] bg-left-bottom bg-no-repeat transition-[background-size] duration-500 hover:bg-[length:100%_3px] group-hover:bg-[length:100%_5px]`}>
                            {socials.facebook}
                        </span>
                    </a>
                </div>
                <div className="flex group w-max items-center mt-2 space-x-2 text-dark-600 dark:text-gray-400">
                    <a href={`https://twitter.com/${socials.twitter}`} className="justify-left items-start group-hover:text-[#00acee] transition-all">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="w-6 h-4 mt-[2px] pl-[4px]" preserveAspectRatio="xMinYMin meet">
                            <path fill="currentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
                        </svg>
                    </a>
                    <a href={`https://twitter.com/${socials.twitter}`} target="_blank" rel="noopener noreferrer">
                        <span className={`bg-gradient-to-r from-[#00acee] bg-[length:0px_5px] bg-left-bottom bg-no-repeat transition-[background-size] duration-500 hover:bg-[length:100%_3px] group-hover:bg-[length:100%_5px]`}>
                            {socials.twitter}
                        </span>
                    </a>
                </div>
                <div className="flex group w-max items-center mt-2 space-x-2 text-dark-600 dark:text-gray-400">
                    <a href={`https://github.com/${socials.github}`} className="justify-left items-start group-hover:text-stone-600 dark:group-hover:text-white transition-all">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="w-6 h-4 pl-1" preserveAspectRatio="xMinYMin meet">
                            <path fill="currentColor" d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z" />
                        </svg>
                    </a>
                    <a href={`https://github.com/${socials.github}`} target="_blank" rel="noopener noreferrer">
                        <span className={`bg-gradient-to-r from-stone-600 bg-[length:0px_5px] bg-left-bottom bg-no-repeat transition-[background-size] duration-500 hover:bg-[length:100%_3px] group-hover:bg-[length:100%_5px]`}>
                            {socials.github}
                        </span>
                    </a>
                </div>
                <div className="flex group w-max items-center mt-2 space-x-2 text-dark-600 dark:text-gray-400">
                    <a href={`https://www.linkedin.com/in/${socials.linkedin}/`} className="justify-left items-start group-hover:text-[#0072b1] transition-all">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="w-6 h-4 pl-[5px]" preserveAspectRatio="xMinYMin meet">
                            <path fill="currentColor" d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
                        </svg>
                    </a>
                    <a href={`https://www.linkedin.com/in/${socials.linkedin}/`} target="_blank" rel="noopener noreferrer">
                        <span className={`bg-gradient-to-r from-[#0072b1] bg-[length:0px_5px] bg-left-bottom bg-no-repeat transition-[background-size] duration-500 hover:bg-[length:100%_3px] group-hover:bg-[length:100%_5px]`}>
                            {socials.linkedin}
                        </span>
                    </a>
                </div>
                <div className="flex group w-max items-center mt-2 space-x-2 text-dark-600 dark:text-gray-400">
                    <a href={`https://youtube.com/@${socials.youtube}`} className="justify-left items-start group-hover:text-red-600 transition-all">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="w-6 h-4 pl-[3px]" preserveAspectRatio="xMinYMin meet">
                            <path fill="currentColor" viewBox="0 0 24 24" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z" />
                        </svg>
                    </a>
                    <a href={`https://youtube.com/@${socials.youtube}`} target="_blank" rel="noopener noreferrer">
                        <span className={`bg-gradient-to-r from-red-600 bg-[length:0px_5px] bg-left-bottom bg-no-repeat transition-[background-size] duration-500 hover:bg-[length:100%_5px] group-hover:bg-[length:100%_5px]`}>
                            {socials.youtube}
                        </span>
                    </a>
                </div>
            </div>
        </section>
    )
}

export default ContactInfo;